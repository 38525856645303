'use client';

import { useEffect } from 'react';

export default function GPTScript() {
  useEffect(() => {
    const existingScript = document.querySelector('script[src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      document.head.appendChild(script);
    }

    window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(() => {
      // Slot Comidoc_Rewarded
      window.googletag
        .defineSlot('/21848807581/Comidoc_Rewarded', [1, 1], 'div-gpt-ad-comidoc')
        .addService(window.googletag.pubads());
    });

    window.googletag.cmd.push(() => {
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
    });
  }, []);

  return null;
};

